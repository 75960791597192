<template>
    <div class="hectemplate">
        <myhead :param="param" />
        <section>
            <div class="bannerhec">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="htable" >
                <div class="hcell" style="width:860px;">
                    <div style="font-size: 60px;">
                        HEC综合能源
                    </div>
                    <div style="font-size: 18px;color: rgba(20,20,20,0.8);line-height: 32px;margin-top: 60px;">
                        重庆辉腾能源股份有限公司将数字信息技术和互联网技术与光伏技术融合，推出了HEC-5000能源管理方案。基于让电站管理更简单、全数字化的理念，采用云、管、端系统架构，融入智能数据采集、高速通讯和云计算技术，帮助电站实现自动运维。全数字化的智能光伏电站可精确检测每路组串信息，监控关键部件状态，实现电站可视化管理。
                    </div>
                    <div style="font-size: 42px;margin-top: 150px;">
                        方案概述
                    </div>
                    <div style="font-size: 18px;color: rgba(20,20,20,0.8);line-height: 32px;margin-top: 24px;">
                        辉腾HEC-5000能源管理方案，采用无线通信技术，构建简单可靠的智能光伏无线传输系统，高速安全地传输电站信息，系统可对所有EPC集成商建设或投资的所有电站信息，通过互联网进行集中统一管理。智能光伏电站的智能化自动运维，利用云计算、大数据挖掘，实现资源集中配置，移动远程运维，远端无人值守，最终提升电站全生命周期内的经营效益。
                    </div>
                    <div class="hec_fa1" style="margin-top: 60px;">
                    </div>

                </div>
                <div class="hcell" style="width:340px;"> </div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                理念与架构
            </div>
            <div class="hec_fa2" style="margin-top: 60px;">
            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                应用场景
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_cj hec_cj1"><span class="hec_cjtxt">工商业屋顶</span></div></div>
                <div class="hcell"><div class="hec_cj hec_cj2"><span class="hec_cjtxt">农光互补</span></div></div>
                <div class="hcell"><div class="hec_cj hec_cj3"><span class="hec_cjtxt">渔光互补</span></div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_cj hec_cj4"><span class="hec_cjtxt">荒山荒地</span></div></div>
                <div class="hcell"><div class="hec_cj hec_cj5"><span class="hec_cjtxt">户用屋顶</span></div></div>
                <div class="hcell"><div class="hec_cj" ></div></div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                方案特点
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_td hec_td1"> </div></div>
                <div class="hcell"><div class="hec_td hec_td2"> </div></div>
                <div class="hcell"><div class="hec_td hec_td3"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_tdd">
                    <img :src="hec_tddi1">
                    <div class="hec_tddh">规模可大可小</div>
                    <div class="hec_tddt">模块化设计，决定了其规模可大可小，可根据场地的要求调整光伏系统的容量。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="hec_tddi2">
                    <div class="hec_tddh">可以发电用电并存</div>
                    <div class="hec_tddt">接入配电网，发电用电并存，且要求尽可能地就地消纳。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="hec_tddi3">
                    <div class="hec_tddh">简单实现自动化</div>
                    <div class="hec_tddt">每一盏路灯都有固定的GPS坐标，维修人员可据此坐标进行导航，方便维修。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_tdd">
                    <img :src="hec_tddi4">
                    <div class="hec_tddh">全球化自动营维实现智能化</div>
                    <div class="hec_tddt">一体化集中管理，远端少人值守，智能化管理、自动化运维。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="hec_tddi5">
                    <div class="hec_tddh">全数字化实现信息化</div>
                    <div class="hec_tddt">开放的高速无线通信网络，云计算，大数据分析，开放的软件平台，开源的云操作系统。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">

                </div></div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                功能阐述
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn1"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">数据采集</div>
                        <div class="hec_gnt">电站各节点设备运行信息：采集、分析、告警、显示。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn2"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">GIS地图监控</div>
                        <div class="hec_gnt">电子地图显示：区域发电站监控终端各节点运行状态信息。</div>
                    </div>
                </div></div>
            </div>

            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn3"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">远程控制</div>
                        <div class="hec_gnt">可调逆变器运行状态输出电压范围调节，以适应电网参数，提高发电量。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn4"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">故障报警</div>
                        <div class="hec_gnt">故障报警信息：直观展示、推送方式设定。</div>
                    </div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn5"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">养护策略</div>
                        <div class="hec_gnt">根据发电站数据分析，分析电站周围遮挡物处理建议，提供组件清洁建议。</div>
                    </div>
                </div></div>
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn6"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">资产管理</div>
                        <div class="hec_gnt">定义、管理及维护行政区域内的设备信息。</div>
                    </div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_gn htable ">
                    <div class="hcell"><img :src="hec_gn7"></div>
                    <div class="hcell" style="width: 342px;">
                        <div class="hec_gnh">清洁预测系统</div>
                        <div class="hec_gnt">搭建降尘检测设备，实时采集精准降尘数据，核心专利算法配置最优清洗方案。</div>
                    </div>
                </div></div>

            </div>

        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                智慧云平台
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                    辉腾HEC云平台是一套基于云计算的物联网云应用平台。支持用户组织架构管理、大数据分析、API接口对
                接、业务平台定制等丰富的功能。提供从终端设备、协议转换、数据采集分析到基于WEB和APP应用的功能
                需求整体实现。可根据用户的实际需求，基于标准化逻辑进行定制，帮助电站提高发电量，增加经济收益。

            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: flex-start;">
                <div class="hcell"><img :src="hec_pt0"> </div>
                <div class="hcell" style="margin-left: 120px; ">
                    <div class="htable" style="margin-top: 18px;">
                        <img :src="hec_pt1">
                        <span style="font-size: 16px;color: rgba(20,20,20,0.8);margin-left: 24px;">
                            云端大数据实时分析平台快速处理数据<br/>
                            智能在线分析平台，多维度展示电站数据<br/>
                            故障信息提示的同时，推送多套消缺方案
                        </span>
                    </div>
                    <div class="htable" style="margin-top: 60px;">
                        <img :src="hec_pt2">
                        <span style="font-size: 16px;color: rgba(20,20,20,0.8);margin-left: 24px;">
                        具备多种标准通讯接口，实现电站快速接入<br/>
                            云端数据分钟级处理，同步推送移动APP<br/>
                            辅助现场运维人员，快速消缺故障
                    </span>
                    </div>
                    <div class="htable" style="margin-top: 60px;">
                        <img :src="hec_pt3">
                        <span style="font-size: 16px;color: rgba(20,20,20,0.8);margin-left: 24px;">
                        采用业界领先的云计算平台、网络安全久经考验<br/>
                            99.99%达到金融级别系统可靠性<br/>
                            7×24小时不间断设备监控
                    </span>
                    </div>
                </div>
            </div>

        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="hec_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 1,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                hsccotr: [],
                gprscotr: [],
                g5cotr: [],
                hec_tddi1: require('../assets/hec_tddi1.png'),
                hec_tddi2: require('../assets/hec_tddi2.png'),
                hec_tddi3: require('../assets/hec_tddi3.png'),
                hec_tddi4: require('../assets/hec_tddi4.png'),
                hec_tddi5: require('../assets/hec_tddi5.png'),
                hec_gn1: require('../assets/hec_gn1.png'),
                hec_gn2: require('../assets/hec_gn2.png'),
                hec_gn3: require('../assets/hec_gn3.png'),
                hec_gn4: require('../assets/hec_gn4.png'),
                hec_gn5: require('../assets/hec_gn5.png'),
                hec_gn6: require('../assets/hec_gn6.png'),
                hec_gn7: require('../assets/hec_gn7.png'),
                hec_pt0: require('../assets/hec_pt0.jpg'),
                hec_pt1: require('../assets/hec_pt1.jpg'),
                hec_pt2: require('../assets/hec_pt2.jpg'),
                hec_pt3: require('../assets/hec_pt3.jpg'),
                hec_ys: require('../assets/hec_ys.png'),
                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .hectemplate{
        color: #202020;
        text-align: left;

    }
    .txtzw{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .bannerhec{
        background-image: url('~assets/hec_banner.jpg');
        height: 600px;
    }
    .hec_fa1{
        background-image: url('~assets/hec_fa1.png');
        height: 357px;
    }
    .hec_fa2{
        background-image: url('~assets/hec_fa2.png');
        height: 614px;
    }
    .hec_cj{
        width: 374px;
        height: 462px;
        border-radius: 10px;
    }
    .hec_cj1{
        background-image: url('~assets/hec_cj1.jpg');
    }
    .hec_cj2{
        background-image: url('~assets/hec_cj2.jpg');
    }
    .hec_cj3{
        background-image: url('~assets/hec_cj3.jpg');
    }
    .hec_cj4{
        background-image: url('~assets/hec_cj4.jpg');
    }
    .hec_cj5{
        background-image: url('~assets/hec_cj5.jpg');
    }
    .hec_cjtxt{
        float: right;
        margin:30px 30px;
        font-size: 24px;
    }
    .hec_td{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .hec_td1{
        background-image: url('~assets/hec_td1.jpg');
    }
    .hec_td2{
        background-image: url('~assets/hec_td2.jpg');
    }
    .hec_td3{
        background-image: url('~assets/hec_td3.jpg');
    }

    .hec_tdd{
        width: 320px;
        height: 180px;
    }
    .hec_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hec_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }
    .hec_gn{
        width: 546px;
        height: 110px;

    }
    .hec_gnh{
        font-size: 24px;
        font-weight: bold;
        margin-top: 8px;
        margin-left: 24px;
    }
    .hec_gnt{
        margin-top: 8px;
        margin-left: 24px;
        font-size: 16px;
        line-height: 24px;
        color: rgba(20,20,20,0.8);
    }

    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }

    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

</style>
